/* Navbar */
 /* src/styles/App.css */
 body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: sans-serif;
    color: rgba(255, 255, 255, 1);;
    position: relative;
    /* color: black; */
    margin-top: -115px;
    margin-bottom: 175px;
    display: flex;
    justify-content: center;
    font-size: 40px;

  }
  
  .container {
    padding: 20px;
  }
  
  .section {
    padding: 50px 0;
  }
  
 h2, h3 {
    color: white; /* Ensure text is visible against the background image */
  }
  
  /* contactus */

  .syntax-contactus{
    margin-bottom: 50px;
  }

  .seconddiv{
    height: 50%;
  }
  .overlay-navbar-contactUs {
    left: 0;
    width: 50%;
    max-width: 550px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(41, 128, 185, 1);
    /* margin-bottom: 50px; */
  }

  .box-1{
    position: absolute;
    background-color: rgba(236, 240, 241, 1);
    width: 100px;
    margin-left: 26.5vw;
    height: 100px;
    margin-top: -14px;
  }
  
  .contactus-container {
    background: white;
    padding: 20px;
    /* border-radius: 10px; */
    max-width: 550px;
    max-height: 500px;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .box-2{
    background-color: rgba(236, 240, 241, 1);
    width: 100px;
    margin-left: 64.5vw;
    height: 150px;
    margin-top: -132px;
    margin-bottom: 50px;
  }
  
  .upper-section-form {
    width: 85%;
    margin: auto;
    border-bottom: 2px solid rgba(204, 204, 204, 0.6);
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .contact-us-form {
    margin-top: 20px;
    line-height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-us-container h1 {
    font-family: Lato, sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: rgba(44, 62, 80, 1);
  }
  .contact-us-container p {
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: rgba(102, 102, 102, 1);
  }
  
  .Contactus-form-fullname {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .Contactus-form-fullname .name-left {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
  }
  
  .Contactus-form-fullname .name-right {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    margin-left: 10px;
  }
  
  .Contactus-form-fullname .name-left input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  .Contactus-form-fullname .name-right input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .Contactus-form-email-phonenumber {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .Contactus-form-email-phonenumber .email {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 50%;
  }
  .Contactus-form-email-phonenumber .email input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .Contactus-form-email-phonenumber .number {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 14px;
    margin-left: 10px;
  }
  .Contactus-form-email-phonenumber .number input {
    height: 25px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .message {
    color: rgba(102, 102, 102, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
  }
  
  .message textarea {
    height: 80px;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-radius: 5px;
  }
  
  .checkbox-container-contactus {
    margin-top: 15px;
    margin-left: 5px;
    width: 100%;
    font-size: 12px;
    line-height: 13px;
    font-weight: 500;
    display: flex;
    color: rgba(102, 102, 102, 1);
  }
  
  .checkbox-container-contactus input {
    border: 1px solid rgba(204, 204, 204, 0.6);
  }
  .checkbox-container-contactus .receive-info {
    margin-left: 20px;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .checkbox-container-contactus .receive-info .receive-info-span{
    color: rgba(41, 128, 185, 1);
  }
  
  .submit-cancel-btn-contactus-form {
    display: flex;
    margin-top: 20px;
  }
  
  /* .submit-cancel-btn-contactus-form .cancel-btn-contactus-form {
    background-color: rgba(204, 204, 204, 1);
    color: rgba(44, 62, 80, 1);
    width: 140px;
    height: 34px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
  } */
  
  .submit-cancel-btn-contactus-form .submit-btn-contactus-form {
    background-color: rgba(44, 62, 80, 1);
    color: rgba(255, 255, 255, 1);
    margin-left: 15px;
    width: 140px;
    height: 40px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
  }

   /* src/styles/Footer.css */
   .thirddiv{
    height: 50%;
  }
.footer-container-contactus {
    margin: 0 auto;
    background-color: rgba(236, 240, 241, 1);
  }
  
  .footer-upper-section {
    /* padding: 50px; */
    display: flex;
    /* justify-content: center; */
  }
  .subscribe {
    width: 100%;
    color: rgba(44, 62, 80, 1);
    display: flex;
    margin-top: 0px;
  }
  .subscribe h2 {
    /* width: 60%; */
    font-family: Lato, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 48px;
    color: rgba(44, 62, 80, 1);
    margin-bottom: 30px;
    margin-left: 80px;
  }
  .input-group {
    width: 26%;
    display: flex;
    height: 37px;
    text-align: center;
    margin: auto;
    margin-left: 25%;
  }
  .input-group input {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid rgba(189, 195, 199, 0.35);
    font-size: 14px;
    font-family: Lato, sans-serif;
    position: relative;
    border-radius: 5px;
    /* margin-left: 20px; */
  }
  .input-group button {
    position: absolute;
    background-color: rgba(44, 62, 80, 1);
    color: white;
    border: none;
    padding: 8px 30px;
    cursor: pointer;
    font-family: Lato, sans-serif;
    font-size: 14px;
    margin-left: 178px;
    border-radius: 5px;
    margin-top: 2px;
  }
  
  .comapnyinfo-socialicons{
    width: 40%;
  }
  
  .company-info {
    width: 100%;
  }
  
  .company-info .logo {
    display: flex;
    align-items: center;
  }
  
  .company-info .logo svg {
    width: 40px;
    height: 40px;
  }
  
  .company-info .logo span {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin-left: 10px;
  }
  
  .company-info p {
    margin-top: 25px;
    width: 75%;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(102, 102, 102, 1);
  }
  
  .social-icons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icons svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    fill: rgba(44, 62, 80, 1);
  }
  
  .footer-lower-section {
    padding: 45px;
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
  }
  
  .quick-links,
  .services,
  .contact-details,
  .legal {
    width: 20%;
  }
  
  .quick-links h3,
  .services h3,
  .contact-details h3 {
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: rgba(44, 62, 80, 1);
    margin-bottom: 10px;
  }
  
  .links,
  .service-links,
  .info {
    display: flex;
    flex-direction: column;
  }
  
  .links div,
  .service-links div{
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 8px;
  }
  
  .info-heading-footer{
    color: rgba(44, 62, 80, 1);
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 600;
    /* color: rgba(102, 102, 102, 1); */
    margin-bottom: 2px;
  }
  .info-heading-content{
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    margin-bottom: 8px;
  }
  
  .legal {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    background-color: rgba(44, 62, 80, 1);
  }
  
  .legal .left-content {
    font-family: Lato, sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .legal .right-content {
    font-family: Lato, sans-serif;
    font-size: 12px;
    color: 
    rgba(255, 255, 255, 0.8);
    cursor: pointer;
    /* text-decoration: underline; */
    margin-left: 10px;
  }
  
  .legal .right-content .right-content-left-span{
   
  }
  
  .legal .right-content .right-content-right-span{
  margin-left: 40px;
  }

  .contact-container {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 30px auto;
    font-family: Arial, sans-serif;
  }
  
  .contact-header {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-top: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    margin-bottom: 15px;
    color: #444;
  }
  
  .contact-icon {
    font-size: 1.6rem;
    color: #4A90E2;
    margin-right: 10px;
  }
  
  .contact-link {
    color: #4A90E2;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }